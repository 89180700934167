<template>
    <a-modal v-model="filterVisible" title="散点图" :footer="null" :centered="true">        
        
        <div class="list">
            <a-table :columns="columns" size="small" :data-source="list" :pagination="false" :rowKey="record=>record.index" :bordered="true">
                <template slot="x" slot-scope="text,record">
                    <a-radio :value="record.index" :checked="record.index===selectedX" @click="select('x',record.index,record.key)"></a-radio>
                </template>
                <template slot="y" slot-scope="text,record">
                    <a-radio :value="record.index" :checked="record.index===selectedY" @click="select('y',record.index,record.key)"></a-radio>
                </template>
            </a-table>
            
        </div>
        
        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../until/tools';
const columns = [
	{
		title: '选择变量',
		dataIndex: 'key',
		key: 'key',
        width:300
	},
	{
		title: 'X轴',
		dataIndex: 'x',
		key: 'x',
        scopedSlots: { customRender: 'x' },
        align:'center'
    },
	{
		title: 'Y轴',
		dataIndex: 'y',
		key: 'y',
		scopedSlots: { customRender: 'y' },
        align:'center'
    },
]
export default {
    name:'ScatterSet',
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            checkedList:[],
            selectedType:1,
            columns,
            selectedX:null,
            selectedY:null,
            selectedXName:null,
            selectedYName:null
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.selectedX = null;
                this.selectedY = null;
                this.selectedXName = null;
                this.selectedYName = null;
                this.$http.getVars({sheetId:this.sheetId}).then(res=>{
                    if(res) {
                        this.list = res;
                        if(res.length<2) {
                            messageTips('系统绘制散点图时，数据表格中需要至少有两行数据，请再选择其他数据。',2);
                            this.filterVisible = false;
                            return false;
                        } else {
                            this.filterVisible = true;
                        }
                    }
                });
                

            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },
        select(type,value,name) {
            if(type==='x') {
                if(value!==this.selectedY) {
                    this.selectedX = value;
                    this.selectedXName = name;
                } else {
                    this.selectedX = value;
                    this.selectedXName = name;
                    this.selectedY = null;
                }
            } else if(type==='y') {
                if(value!==this.selectedX) {
                    this.selectedY = value;
                    this.selectedYName = name;
                } else {
                    this.selectedY = value;
                    this.selectedYName = name;
                    this.selectedX = null;
                }
            }
        },
        goApply() {
            if(!this.selectedX){
                messageTips('请选择x轴',2);
                return false;
            }
            if(!this.selectedY){
                messageTips('请选择y轴',2);
                return false;
            }
            let params = {
                sheetId:this.sheetId,
                xStr:this.selectedX,
                yStr:this.selectedY
            }
            this.$http.getPointChart(params).then(res=>{
                if(res&&res.series) {
                    this.$emit('scatterSetCtrl',{data:res.series[0].data,xName:this.selectedXName,yName:this.selectedYName});
                    this.filterVisible = false;
                }
            })
        },
        
        
    }
}
</script>

<style lang="scss" scoped>
.list {
    height:300px;
    overflow-y: auto;
}
</style>