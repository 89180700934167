import Handsontable from 'handsontable/dist/handsontable.full.js';
// const multiBg = require('@/assets/multi-bg.png');

var _table = null;
var _getIndicotor = null;
var _showCode = false;
var _fixedRowsTop = 0;
var _fixedColumnsLeft = 0;
function createSettings(table,getIndicotor,commentCells=[]) {
    _table = table;
    if(_table.floatNum===null||_table.floatNum===undefined) {
        _table.floatNum = 2;
    }
    _getIndicotor = getIndicotor;
    var settings = {
        manualRowResize: true,
        manualColumnResize: true,
        outsideClickDeselects: false,
        rowHeaders: true,
        colHeaders: true, 
        data: table.data,
        width:'100%',
        height:'100%',
        selectionMode: 'multiple',
        search: {
            searchResultClass: 'my-custom-search-result-class'
        },
        mergeCells: table.mergeCells,
        fixedRowsTop: table.fixedRowsTop,
        fixedColumnsLeft: table.fixedColumnsLeft,
        licenseKey: 'non-commercial-and-evaluation',
        comments:true,
        cell:commentCells,
        cells: function() {
            var cellProperties = {};
            if(table.editable) {
                cellProperties.readOnly = false;
            } else {
                cellProperties.readOnly = true;
            }            
            cellProperties.renderer = PolicemenRenderer;
            return cellProperties;
        },
    };
    return settings;
}

function PolicemenRenderer (instance, td, row, col, prop, value,properties) {
    var that = this;
    const escaped = Handsontable.helper.isNumeric(value);
    if (escaped) {
      td.style.textAlign='right';
    }
    var fs = (new Array(_table.floatNum + 1)+'').replace(/,/g, '0');
    if(row>=_table.fixedRowsTop&&col>=_table.fixedColumnsLeft) {
        properties.numericFormat = {};
        if(_table.needSetCellFormatRows) {
            if(_table.needSetCellFormatRows.indexOf(row)!==-1) {
                if(_table.thousandsSeparator) {
                    properties.numericFormat.pattern='0,0.'+fs;
                } else {
                    properties.numericFormat.pattern='00.'+fs;
                }
                if(_table.moneyFormat==='en-US') {//中文的货币在下方单独处理
                    properties.numericFormat.pattern='0,0.'+fs+'$';
                    properties.numericFormat.culture = _table.moneyFormat;
                }
                if(_table.percentFormat){
                    properties.numericFormat.pattern='0,0.'+fs+'%';
                }
            } else {
                properties.numericFormat =  {
                    pattern: '0,0.00'
                }
            }
        } else {
            properties.numericFormat =  {
                pattern: '0,0.00'
            }
        }
    }
    Handsontable.renderers.NumericRenderer.apply(this, arguments);

    if (_table.showIcons[row] && _table.showIcons[row][col]) {
        var colSpecial = _table.showIcons[row][col];
        switch(colSpecial.cellType) {
            case 'INDICATOR': 
            case 'REPORT_INDICATOR': 
            IndicatorRenderer.apply(that, arguments);
            break;
            case 'time':
            TimeRenderer.apply(that, arguments);
            break;
            default:
            break;
        }
        if (colSpecial.calc) { CalcRenderer.apply(that, arguments); }
    }
    //单独处理中文货币格式
    if(_table.moneyFormat==='zh-CN') {
        if(row>=_table.fixedRowsTop&&col>=_table.fixedColumnsLeft) {
            renminbiFormat.apply(that, arguments);
        }
    }
    RowRenderer.apply(this, arguments);
}



function RowRenderer(instance, td, row, col) {
    //偶数行白色相间
    if(row<_table.fixedRowsTop) {//头部固定栏单元格居中，并添加下边框
        td.style.textAlign='center';
    }
    var bgColor =  _table.conStyles&&_table.conStyles[row]&&_table.conStyles[row][col]?_table.conStyles[row][col].bgColor:null;
    var conIcon =_table.conStyles&&_table.conStyles[row][col]?_table.conStyles[row][col].icon:null;
    var fontStyles = _table.conStyles&&_table.conStyles[row][col]?_table.fontStyles[row][col].fontStyle:null;
    var multiSource = _table.multiSource&&_table.multiSource[row][col]?_table.multiSource[row][col]:null;
    if(bgColor) {//添加条件样式背景
        td.style.backgroundColor=bgColor;
    }
    if(conIcon) {//添加条件样式数据分段图表
        let para = document.createElement("i");
        para.setAttribute('class','icon-constyle icon-'+conIcon);
        td.insertBefore(para, td.firstChild)
    }
    if(fontStyles) {
        for(let i in fontStyles) {
            td.style[i] = fontStyles[i];
        }
    }
    if(multiSource>0) {
        // td.setAttribute('class','multi-source');
        td.style.borderColor = '#f00';
    }
    
    if(row == _table.fixedRowsTop-1&&!_table.nosort) {//添加排序图表
        if(col>=_table.fixedColumnsLeft) {
            let para = document.createElement("i");
            if(_table.s4&&_table.s4.index===col) {
                if(_table.s4.orderStr==='gt') {
                    para.setAttribute('class','icon-sort icon-sort-gt current');
                } else if(_table.s4.orderStr==='lt') {
                    para.setAttribute('class','icon-sort icon-sort-lt current');
                } else {
                    para.setAttribute('class','icon-sort');
                }
            } else {
                para.setAttribute('class','icon-sort');
            }
            para.onclick = ()=>{_table.sort(col,_table.s4.orderStr)};
            let child = td.innerHtml;
            td.insertBefore(para,child);
        }        
    }
}

function IndicatorRenderer(instance, td) {
    Handsontable.renderers.HtmlRenderer.apply(this, arguments);
    var para = document.createElement("i");
    para.setAttribute('class','iconfont icon-info');
    para.onclick = (e)=>{
        e.stopPropagation();
        _getIndicotor(_table.showIcons[arguments[2]][arguments[3]])
    }
    td.appendChild(para);
}
function TimeRenderer(instance, td){
    var value = null;
    var string = td.innerText;
    if(string.indexOf(',')!==-1) {
        value = parseInt(string.replace(/,/g, ''));
    } else {
        value = string;
    }
    var result = value.toString();
    td.innerText = result;
}
function renminbiFormat(instance,td) {
    let para = document.createElement("span");
    if(td.innerText!=='') {
        para.innerText = '￥';
    }    
    td.insertBefore(para, td.firstChild)
}

// 计算渲染器
function CalcRenderer(instance, td, row, col, prop, value) {
    var calc = arguments[6]['mydata'].calc;
    if (!value || isNaN(value)) { return; }
    if (calc.e === true) { value = Math.log(value); }
    if (calc.percent === true) {
        //value = value * 100;
        arguments[6].format = '0,0%';
    }
    arguments[5] = value;
    Handsontable.renderers.NumericRenderer.apply(this, arguments); // 数字格式化
}



/**
 * 表格类, handsontable配置映射
 * @param {Array} data 二维数组
 * @param {Object} idmap id与坐标映射
 * @param {Array} special 特殊坐标的配置
 * @param {Number} floatNum 控制小数位
 * @param {Array} mergeCells 合并单元格描述
 * @param {Number} fixedRowsTop 冻结行
 * @param {Number} fixedColumnsLeft 冻结列
 */
function Table(data, idmap, special, floatNum, mergeCells, fixedRowsTop, fixedColumnsLeft,conStyles,fontStyles,showIcons,multiSource) {
    this.data = data;
    this.idmap = idmap; //
    this.special = special;
    this.floatNum = floatNum; //
    this.mergeCells = mergeCells;
    this.fixedRowsTop = fixedRowsTop;
    this.fixedColumnsLeft = fixedColumnsLeft;
    this.conStyles = conStyles;
    this.fontStyles = fontStyles;
    this.showIcons = showIcons;
    this.multiSource = multiSource;
}
  
/**
 * 解析成表格对象
 * @param  {Object} tableSouce 源数据
 * @return {Table}
 */
function tableParse(tableSouce,showCode) {
    _showCode = showCode;
    var data = [], merges = [], idmap = {},conStyles = [],fontStyles=[],multiSource=[];
    var values = tableSouce.values;
    var special = tableSouce.infoIconPosLst?tableSouce.infoIconPosLst:[];
    _fixedRowsTop = tableSouce.fixedRowsTop;
    _fixedColumnsLeft = tableSouce.fixedColumnsLeft;
    var kbo = extract(values);
    data = kbo.data;
    idmap = kbo.idmap;
    merges = kbo.merges;
    conStyles = kbo.conStyles;
    fontStyles = kbo.fontStyles;
    multiSource = kbo.multiSource;
    var showIcons = kbo.showIcons;
    return new Table(data, idmap, special, 2, merges, _fixedRowsTop, _fixedColumnsLeft,conStyles,fontStyles,showIcons,multiSource);
}
  

  
// 表格值格式化
function cellFormat(cell,r,c) {
    if(_showCode) {
        if(r<_fixedRowsTop||c<_fixedColumnsLeft) {
            if(cell.showValue) {
                return cell.obj+'-'+cell.showValue;
            } else {
                return null;
            }
        } else {
            return cell.showValue;
        }                
    } else {
        return cell.showValue;
    }    
}
// 提取生成合并值
function cellMerge(r, c, cell) {
    if (cell.colSpan || cell.rowSpan) {
        return {
            row: r, col: c,
            colspan: cell.colSpan + 1,
            rowspan: cell.rowSpan + 1
        };
    }
}

function conStyles(r,c,cell) {
    if(cell.bgColor&&cell.icon) {
        return {bgColor:cell.bgColor,icon:cell.icon}
    } else if(cell.bgColor&&!cell.icon) {
        return {bgColor:cell.bgColor}
    } else if(!cell.bgColor&&cell.icon) {
        return {icon:cell.icon}
    } else if(!cell.bgColor&&!cell.icon) {
        return {bgColor:null,icon:null}
    }
}
  
function fontStyles(r,c,cell) {//8020分析所用
    if(cell.fontStyle) {
        var obj = JSON.parse(cell.fontStyle);
        var result = {
            color:obj.color,
            fontSize:obj.fontSize
        }
        if(obj.fontStyles.indexOf('italic')!==-1) {
            result.fontStyle = 'italic';
        }
        if(obj.fontStyles.indexOf('bolder')!==-1) {
            result.fontWeight = 'bold';
        }
        if(obj.fontStyles.indexOf('underline')!==-1) {
            result.textDecoration = 'underline';
        }
        return {fontStyle:result}
    } else {
        return {fontStyle:{fontStyle:'normal',fontWeight:'normal',textDecoration:'none'}}
    }
}

function getMultiSource(r,c,cell) {
    return cell.sourceCount
}

  
/**
 * 从表格值中提取所需要的数据
 * @param  {Array} values 源表格值
 * @return {Object} ()
 */
function extract(values) {
    var result = {
        'data': [], 
        'merges': [],
        'idmap': {'id':{}, 'coor':{}},
        'conStyles':[],
        'fontStyles':[],
        showIcons:[],
        multiSource:[]
    };
    var row = [];
    traverseTwoDimeArray(
        values,
        function() { row = []; },
        function(r, c, cell) {
            if (cell === null) { cell = {}; } 
            var value = cellFormat(cell,r, c);
            var merge = cellMerge(r, c, cell);
            var conStyle = conStyles(r,c,cell);
            var fontStyle = fontStyles(r,c,cell);
            var showIcon = getHasIcons(r,c,cell);
            var multiSource = getMultiSource(r,c,cell);
            // 映射
            var id = cell.cellId || r+','+c;
            if (!result.idmap.id[r]) { result.idmap.id[r] = {}; }
            result.idmap.id[r][c] = id;
            result.idmap.coor[id] = r+','+c;
            row.push(value);
            if(merge) { result.merges.push(merge); }
            if(result.showIcons[r]) {
                result.showIcons[r].push(showIcon);
            } else {
                result.showIcons[r] = [];
                result.showIcons[r].push(showIcon);
            }
            if(result.conStyles[r]) {
                result.conStyles[r].push(conStyle);
            } else {
                result.conStyles[r] = [];
                result.conStyles[r].push(conStyle);
            }
            if(result.fontStyles[r]) {
                result.fontStyles[r].push(fontStyle);
            } else {
                result.fontStyles[r]=[];
                result.fontStyles[r].push(fontStyle);
            }
            if(result.multiSource[r]) {
                result.multiSource[r].push(multiSource);
            } else {
                result.multiSource[r]=[];
                result.multiSource[r].push(multiSource);
            }
        },
        function() { result.data.push(row);}
        );
    return result;
}

/**
 * 提取特殊的单元格属性
 * @param  {xia} source 
 * @return {rnum:{cnum:{}}}
 */
function getHasIcons(r,c,cell) {
    return {cellType:cell.cellType,code:cell.leftCellId,obj:cell.obj};
}


/**
 * 遍历二维数组, 读每行及每列时执行回调
 * @param  {Array} twoArray 二维数组
 * @param  {Function} rowProcess 读行的回调(行号, 行数据)
 * @param  {Function} colProcess 读列的回调(列号, 列数据)
 * @param  {Function} rowAfterProcess 改行读完后(列号, 列数据)
 */
function traverseTwoDimeArray(twoArray, rowProcess, colProcess, rowAfterProcess) {
    for (var r = 0, rlen = twoArray.length; r < rlen; r++) {
        var rowData = twoArray[r];
        if (rowProcess) { rowProcess(r, rowData); }
        for (var c = 0, clen = rowData.length; c < clen; c++) {
        var cellData = rowData[c];
        if (colProcess) { colProcess(r, c, cellData); }
        }
        if (rowAfterProcess) { rowAfterProcess(r, rowData); }
    }
}

export {createSettings,tableParse};