export const pageLayoutSiderStyle = {
    width:'268px',
    flex:'0 0 268px',
    maxWidth:'268px',
    minWidth:'268px',
    background:'#fff',
    padding:'10px 10px'
}
export const pageLayoutSiderHideStyle = {
    width:'30px',
    flex:'0 0 30px',
    maxWidth:'30px',
    minWidth:'30px',
    background:'#fff',
    padding:'10px 10px'
}