<template>
    <a-modal v-model="filterVisible" title="自定义图表" wrapClassName="user-chart-win" :footer="null" :width="600" :centered="true" @cancel="cancel">
        <a-row :gutter="[16,16]">
            <a-col :span="11">
                <div class="h2">图表名称</div>
                <a-input v-model="chartName" />
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="11">
                <div class="h2">图表类型</div>
                <a-select v-model="selectedType" style="width:100%" @change="changeType">
                    <a-select-option v-for="item in typeList" :key="item.name" :value="item.name">{{item.title}}</a-select-option>
                </a-select>
            </a-col>
        </a-row>

        <a-row :gutter="[16,16]">
            <a-col :span="24">
                <div class="h2">序列<span v-if="selectedType==='pie'" style="margin-left:10px;color:red;font-weight:normal">饼图只能绘制第一条</span></div>
                <div class="series">
                    <div class="item" v-for="(item,key) in series" :key="item.index" @click="setSeriesAttr(key)" :class="{'active':selectedSeries===key}">
                        <a-checkbox :checked="chartSeries[key]&&chartSeries[key].checked" @change="changeChartSeriesStatus"></a-checkbox>{{item.key}}
                    </div>
                </div>
            </a-col>
        </a-row>

        <div class="series-set">
            <a-row :gutter="[16,16]">
                <a-col :span="12">
                    <a-row type="flex">
                        <a-col flex="80px">
                            <div class="label">序列类型：</div>
                        </a-col>
                        <a-col flex="auto">
                            <a-select v-if="chartSeries[selectedSeries]" :disabled="selectedType!=='bar'&&selectedType!=='line'" v-model="chartSeries[selectedSeries].type" style="width:100%">
                                <a-select-option v-for="(item,key) in typeList" :disabled="key>1" v-show="key<2" :key="item.name" :value="item.name">{{item.title}}</a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="12">
                    <div class="label" v-if="chartSeries[selectedSeries]">
                        <a-checkbox :checked="chartSeries[selectedSeries].yAxisIndex===1" @change="setSecondYAxis">第二坐标轴上的位置</a-checkbox>
                    </div>
                </a-col>
                <a-col :span="24" style="border-top:1px solid #ddd;">
                    <a-row type="flex">
                        <a-col flex="80px">
                            <div class="label">线条颜色</div>
                        </a-col>
                        <a-col flex="auto">
                            <div class="color-select">
                                <colorPicker v-if="chartSeries[selectedSeries]" v-model="chartSeries[selectedSeries].itemStyle.color" />
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <div class="cover" v-show="specialType.indexOf(selectedType)!==-1"></div>
        </div>

        <a-row :gutter="[16,16]">
            <a-col :span="11">
                <div class="h2">视图：</div>
                <a-row type="flex">
                    <a-col flex="80px">
                        <div class="label">背景颜色：</div>
                    </a-col>
                    <a-col flex="auto">
                        <div class="color-select">
                            <colorPicker v-model="selectedBgColor" />
                        </div>
                        <div class="label label2">
                            <a-checkbox :checked="showSplitLine" @change="e=>{showSplitLine=e.target.checked}">显示网格线</a-checkbox>
                        </div>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="11">
                <div class="h2">标签</div>
                <div class="label">
                    <a-checkbox :checked="showLabel" @change="e=>{showLabel=e.target.checked}">显示数据标签</a-checkbox>
                </div>
            </a-col>
        </a-row>

        <a-row :gutter="[16,16]">
            <a-col :span="11">
                <div class="h2">图例：</div>
                <a-row type="flex">
                    <a-col flex="100px">
                        <div class="label">
                            <a-checkbox :checked="showLegend" @change="e=>{showLegend=e.target.checked}">显示图例</a-checkbox>
                        </div>
                    </a-col>
                    <a-col flex="auto">
                        <a-select v-model="legendPosition" style="width:100%">
                            <a-select-option value="bottom">底部</a-select-option>
                            <a-select-option value="right">右侧</a-select-option>
                        </a-select>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="11">
                <div class="h2">坐标轴刻度设置：</div>
                <div class="label">
                    <a-radio-group v-model="scale">
                        <a-radio :value="0">无</a-radio>
                        <a-radio :value="1">最小值</a-radio>
                    </a-radio-group>
                </div>
            </a-col>
        </a-row>

        <div class="btns">
            <a-button type="primary" ghost @click="cancel">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import {mixChartOtpion} from '../until/chart';
import config from '../until/configs';
import axios from 'axios';
import { messageTips } from '../until/tools';
export default {
    name:'UserChart',   
    props:['visible','sheetId','tableData','source'],
    data() {
        return {
            filterVisible:false,
            chartName:'',
            selectedType:'bar',
            selectedType2:'bar',
            selectedSeries:null,
            showLegend:true,
            showLabel:false,
            showSplitLine:false,
            series:[],
            specialType:['pie','radar','rotateBar','stackRotateBar'],
            selectedBgColor:'#ffffff',
            legendPosition:'bottom',
            scale:0,
            chartData:null,
            cols:[],
            chartSeries:[]
        }
    },
    computed:{
        typeList() {
            if(this.source===1) {
                return [
                    {name:'line',title:'折线图'},
                    {name:'bar',title:'柱状图'},
                    {name:'rotateBar',title:'条形图'},
                    {name:'stackBar',title:'堆积柱状图'},
                    {name:'stackRotateBar',title:'堆积条形图'},
                    {name:'area',title:'面积图'},
                    {name:'pie',title:'饼图'},
                    {name:'radar',title:'雷达图'}
                ]
            } else {
                return [
                    {name:'line',title:'折线图'},
                    {name:'bar',title:'柱状图'},
                    {name:'area',title:'面积图'}
                ]
            }
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.series = [];
                this.filterVisible = true;
                this.chartName = '';
                axios.all([this.getRows(),this.getCols()]).then(axios.spread((data,data2)=>{
                    this.series = data;
                    this.cols = data2;
                    this.selectedSeries = 0;
                    this.setChartData();
                }));                
                
            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancel() {
            this.filterVisible = false;
        },
        goApply() {
            let selectedLength = 0;
            this.chartSeries.forEach(item=>{
                if(item.checked) {
                    selectedLength+=1;
                }
            });
            if(selectedLength===0) {
                messageTips('请选择序列',2);
                return false;
            }
            let option = {...mixChartOtpion};
            option.backgroundColor = this.selectedBgColor;
            let legend = [];
            let series = [];
            let xNames = [];
            if(this.selectedType==='bar'||this.selectedType==='stackBar'||this.selectedType==='line'||this.selectedType==='area') {                
                option.xAxis = {
                    type: 'category',
                    splitLine:{show:this.showSplitLine}
                };
                option.yAxis = [
                    {
                        type: 'value',
                        min: this.scale===1?'dataMin':0,
                        splitLine:{show:this.showSplitLine}
                    },
                    {
                        type: 'value',
                        min: this.scale===1?'dataMin':0,
                        splitLine:{show:this.showSplitLine}
                    }
                ];
                this.chartSeries.forEach((item,key)=>{
                    if(item.checked) {
                        legend.push(this.series[key].key);
                        let obj = {
                            data:item.data,
                            itemStyle:item.itemStyle,
                            maxWidth:30,
                            name:this.series[key].key,
                            yAxisIndex:item.yAxisIndex
                        };
                        if(['bar','stackBar'].indexOf(item.type)!==-1) {
                            obj.type='bar';
                            obj.label = {
                                show:this.showLabel,
                                distance: 15,
                                align: 'left',
                                verticalAlign: 'middle',
                                formatter: '{c}',
                                fontSize: 12,
                                rotate:90,
                                position:'insideBottom'
                            }
                        } else if(['line','area'].indexOf(item.type)!==-1) {
                            obj.type = 'line';                                                
                            obj.label={
                                show:this.showLabel,
                                distance: 15,
                                align: 'left',
                                verticalAlign: 'middle',
                                formatter: '{c}',
                                fontSize: 12,  
                                rotate:0,  
                                position:'top'  
                            }
                        }
                        if(this.selectedType==='stackBar') {
                            obj.stack = 'total';
                        }
                        if(this.selectedType==='area') {
                            obj.areaStyle = {};
                            obj.emphasis = {
                                focus: 'series'
                            };
                        }
                        series.push(obj);
                    }
                });
                this.cols.forEach((item)=>{
                    xNames.push(item.key);
                });
                option.xAxis.data = xNames;
                option.legend.data = legend;
                option.series = series;
            }else if(this.selectedType==='rotateBar'||this.selectedType==='stackRotateBar') {
                option.xAxis = {
                    type: 'value',
                    min: this.scale===1?'dataMin':0,
                    splitLine:{show:this.showSplitLine}
                };
                option.yAxis = {
                    type: 'category',
                    splitLine:{show:this.showSplitLine}
                };
                this.chartSeries.forEach((item,key)=>{
                    if(item.checked) {
                        legend.push(this.series[key].key);
                        let obj = {
                            data:item.data,
                            itemStyle:item.itemStyle,
                            maxWidth:30,
                            name:this.series[key].key,
                            type:'bar',
                            label : {
                                show:this.showLabel,
                                distance: 15,
                                align: 'left',
                                verticalAlign: 'middle',
                                formatter: '{c}',
                                fontSize: 12,
                                rotate:0,
                                position:'insideLeft'
                            }
                        };
                        if(this.selectedType==='stackRotateBar') {
                            obj.stack = 'total';
                        }
                        series.push(obj);
                    }
                });
                this.cols.forEach((item)=>{
                    xNames.push(item.key);
                });
                option.yAxis.data = xNames;
                option.legend.data = legend;
                option.series = series;
            } else if(this.selectedType==='pie') {
                this.cols.forEach((item)=>{
                    xNames.push(item.key);
                });
                option.legend.data = xNames;
                option.series[0] = {
                    name: '',
                    type: 'pie',
                    radius: ['0%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                }
                let data = [];
                this.chartSeries.some((item,key)=>{
                    if(item.checked) {
                        data = item.data;
                        option.series[0].name = this.series[key].key;
                    }
                });
                let series = data.map((item,key)=>{
                    return {
                        name:xNames[key],
                        value:item
                    }
                });
                option.series[0].data = series;
            } else if(this.selectedType==='radar') {
                let indicators = [];
                let series = [];
                this.chartSeries.forEach((item,key)=>{
                    if(item.checked) {
                        legend.push(this.series[key].key);
                        indicators.push({name:this.series[key].key,max:10000});
                        series.push({name:this.series[key].key,value:item.data});
                    }
                });
                option.legend.data = legend;
                option.radar = {indicator:indicators}
                option.series[0] = {data:series,type: 'radar',};
            }

            option.legend.show = this.showLegend;
            if(this.legendPosition==='right') {
                option.legend.orient = 'vertical';
                option.legend.align = 'right';                
                option.legend.right = 0;
                option.legend.top = 'middle';
                option.grid = {right:200}
            } else {
                option.legend.orient = 'horizontal';
                option.legend.align = 'auto';                
                option.legend.right = 'auto';
                option.legend.top = 'auto';
                option.legend.bottom = 0;
                option.grid = {right:100}
            }
            if(this.chartName!=='') {
                option.title = {text:this.chartName,textAlign:'center',left:'50%',top:10}
            }
            this.$emit('setUserChart',{option:option,showLegend:this.showLegend,showLabel:this.showLabel});
            this.filterVisible = false;
        },
        getRows() {
            let params = {sheetId:this.sheetId}
            return this.$http.getVars(params)
        },
        getCols() {
            let params = {sheetId:this.sheetId}
            return this.$http.getColumnsMsg(params)
        },
        changeType(type) {
            this.chartSeries.forEach(item=>{
                item.type = type;
            });
            if(this.specialType.indexOf(type)!==-1) {
                return false;
            }
            this.selectedType2 = type;
        },

        setSeriesAttr(key) {
            this.selectedSeries = key;
        },
        changeChartSeriesStatus(e) {
            this.chartSeries[this.selectedSeries].checked = e.target.checked;
        },
        setSecondYAxis(e) {
            if(e.target.checked) {
                this.chartSeries[this.selectedSeries].yAxisIndex = 1;
            } else {
                this.chartSeries[this.selectedSeries].yAxisIndex = 0;
            }
        },
        setChartData() {

            let data = [];
            this.tableData.tableData.forEach((item,key)=>{
                if(key>this.tableData.fixedTop-1) {
                    let arr = [];
                    item.forEach((i,k)=>{
                        if(k>this.tableData.fixedLeft-1) {
                            arr.push(i);
                        }
                    });
                    data.push(arr);
                }
            })
            this.chartSeries = data.map((item,key)=>{
                return {
                    checked:false,
                    type:this.selectedType,
                    data:item,
                    maxWidth:30,
                    yAxisIndex:0,
                    itemStyle:{
                        color:config.chartColors[(key%10)]
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss">
.user-chart-win {
    .series-set {
        border:1px solid #ddd;
        margin-top: 20px;
        padding:10px;
        background:#fafafa;
        position: relative;
        .cover {
            background:rgba($color: #ddd, $alpha: 0.2);
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:50%;
        }
    }
    .h2 {
        line-height: 32px;
        font-weight: bold;
    }
    .label {
        line-height: 32px;
    }
    .label2 {
        display: inline-block;
        margin-left: 20px;
    }
    .color-select {
        width:24px;
        height:24px;
        border:1px solid #ddd;
        display: inline-block;
        padding:1px;
        position: relative;
        top:4px;
    }
    .series {
        height:150px;
        overflow: auto;
        border:1px solid #ddd;
        padding:5px 0;
        .item {
            padding:5px 10px;
            cursor: pointer;
            .ant-checkbox-wrapper {
                margin-right: 10px;
            }
        }
        .active {
            background:#f0f3f6;
        }
    }
}

</style>