import {thousandSplit} from './tools';
import config from './configs';
const lineOptions = {
    backgroundColor:'#fff',
    tooltip : {
        trigger: 'item',
        formatter: function(params) {
            var name = params.seriesName;
            return name+':'+thousandSplit(params.value);
        }
    },
    legend: {
        orient: 'horizontal',
        type: 'scroll',
        pageIconSize:10,
        pageIconInactiveColor:'#aaa',
        pageIconColor:'#bbb',
        pageTextStyle:{
            color:'#aaa'
        },
        bottom:0, 
    },
    color:config.chartColors,

    xAxis: {
        type: 'category',
    },
    yAxis: {
        type: 'value',

    },
    series: []
};

const rotateBarOptions = {
    backgroundColor:'#fff',
    tooltip : {
        trigger: 'item',
        formatter: function(params) {
            var name = params.seriesName;
            return name+':'+thousandSplit(params.value);
        }
    },
    legend: {
        orient: 'horizontal',
        type: 'scroll',
        pageIconSize:10,
        pageIconInactiveColor:'#aaa',
        pageIconColor:'#bbb',
        pageTextStyle:{
            color:'#aaa'
        },
        bottom:0, 
    },
    color:config.chartColors,

    xAxis: {
        type: 'value',
    },
    yAxis: {
        type: 'category',

    },
    series: []
};

const pieOption = {
    backgroundColor:'#fff',
    color:config.chartColors,
    tooltip: {
        trigger: 'item'
    },
    legend: {
        orient: 'horizontal',
        type: 'scroll',
        pageIconSize:10,
        pageIconInactiveColor:'#aaa',
        pageIconColor:'#bbb',
        pageTextStyle:{
            color:'#aaa'
        },
        bottom:0, 
    },
    series: [
        {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: []
        }
    ]
};


const radarOption = {
    backgroundColor:'#fff',    
    color:config.chartColors,
    title: {
        text: ''
    },
    legend: {
        orient: 'horizontal',
        type: 'scroll',
        pageIconSize:10,
        pageIconInactiveColor:'#aaa',
        pageIconColor:'#bbb',
        pageTextStyle:{
            color:'#aaa'
        },
        bottom:0, 
    },
    radar: {
        indicator: []
    },
    series: [
        {
            name: '',
            type: 'radar',
            data: []
        }
    ]
};

const scatterOption = {
    backgroundColor:'#fff',
    title : {
        text: '',
        x:'center',
        y:'bottom',
        textStyle:{
            fontSize: 14,
            fontWeight: 'normal',
            color: '#282828'
        }
    },
    tooltip : {
        trigger: 'axis',
        showDelay : 0,  
        axisPointer:{
            show: true,
            type : 'cross',
            lineStyle: {
                type : 'dashed',
                width : 1
            }
        }
    },
    xAxis: {},
    yAxis: {},
    series: [
        {
            symbolSize: 20,
            data: [],
            type: 'scatter'
        }
    ]
};

var option;
var timer;
export const setCharts = (chartHandle,type,data,flag,flag2)=>{
    if(type==='line') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'line',
                    data:item,                    
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,  
                        rotate:0,  
                        position:'top'  
                    }
                }
            })
        } else {
            series = [];
        }
        
        option.series = series;
    } else if(type==='bar') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas&&data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:90,
                        position:'insideBottom'
                    }
                }
            })
        } else {
            series= [];
        }
        option.series = series;
    } else if(type==='rotateBar') {
        option = {...rotateBarOptions};
        option.xAxis.data = data.yNames;
        option.yAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:0,
                        position:'insideLeft'
                    }
                }
            })
        } else {
            series = [];
        }
        option.series = series;
    } else if(type==='stackBar') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    stack:'total',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:90,
                        position:'insideBottom'
                    }
                }
            })
        } else {
            series = [];
        }
        option.series = series;
    } else if(type==='stackRotateBar') {
        option = {...rotateBarOptions};
        option.xAxis.data = data.yNames;
        option.yAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    stack:'total',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:0,
                        position:'insideLeft'
                    }
                }
            })
        } else {
            series = [];
        }
        option.series = series;
    }  else if(type==='area') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'line',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,  
                        rotate:0,  
                        position:'top'  
                    }
                }
            });
        } else {
            series = [];
        }
        option.series = series;
    } else if(type==='pie') {
        option = {...pieOption};
        option.legend.data = data.xNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas[0].map((item,key)=>{
                return {
                    name:data.xNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].name = data.yNames[0];
        option.series[0].radius = ['0', '70%'];
        option.series[0].data = series;
    } else if(type==='ring') {
        option = {...pieOption};
        option.legend.data = data.xNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas[0].map((item,key)=>{
                return {
                    name:data.xNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].name = data.yNames[0];
        option.series[0].radius = ['40%', '70%'];
        option.series[0].data = series;
    } else if(type==='radar') {
        option = {...radarOption};
        option.radar.indicator = data.xNames.map(item=>{
            return {name:item,max:data.maxVal}
        });
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].areaStyle = null;
        option.series[0].data = series;
    } else if(type==='fillRadar') {
        option = {...radarOption};
        option.radar.indicator = data.xNames.map(item=>{
            return {name:item,max:data.maxVal}
        });
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].areaStyle = {};
        option.series[0].data = series;
    } else if(type==='scatter') {
        option = {...scatterOption},
        option.series[0].data = data.data;
        option.title.text = data.xName;
        option.yAxis.name = data.yName;
    }
    if(option.legend) {
        option.legend.show = flag;
    }    
    chartHandle.setOption(option);
}

export const showChartLegendFn = (chartHandle,flag) =>{
    option.legend.show = flag;
    chartHandle.setOption(option);
}

export const showChartDataLabelFn = (chartHandle,flag,type) =>{
    let arr = ['line','area','bar','rotateBar','stackBar','stackRotateBar'];
    if(arr.indexOf(type)===-1) {
        return false;
    }
    option.series.forEach(item=>{
        let obj = {
            show:flag,
            distance: 15,
            align: 'left',
            verticalAlign: 'middle',
            formatter: '{c}',
            fontSize: 12,      
        }
        if(type==='bar'||type==='stackBar') {
            obj.rotate = 90;   
            obj.position = 'insideBottom';
        } else if(type==='rotateBar'||type==='stackRotateBar') {
            obj.rotate = 0;   
            obj.position = 'insideLeft';
        } else {             
            obj.rotate = 0;   
            obj.position = 'top';
        }
        item.label = obj;
    });
    chartHandle.setOption(option);
}


export const mixChartOtpion = {
    tooltip : {
        trigger: 'item',
        formatter: function(params) {
            var name = params.seriesName;
            return name+':'+thousandSplit(params.value);
        }
    },
    legend: {
        orient: 'horizontal',
        type: 'scroll',
        pageIconSize:10,
        pageIconInactiveColor:'#aaa',
        pageIconColor:'#bbb',
        pageTextStyle:{
            color:'#aaa'
        },
        bottom:0, 
    },
    
    series: []
}


export const setBigChart = (chartHandle,type,data,flag,flag2)=>{
    if(type==='line') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;
        option.dataZoom = getDataZoom();
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'line',
                    data:item,                    
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,  
                        rotate:0,  
                        position:'top'  
                    }
                }
            })
        } else {
            series = [];
        }
        
        option.series = series;
    } else if(type==='bar') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;        
        option.dataZoom = getDataZoom();
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:90,
                        position:'insideBottom'
                    }
                }
            })
        } else {
            series= [];
        }
        option.series = series;
    } else if(type==='rotateBar') {
        option = {...rotateBarOptions};
        option.xAxis.data = data.yNames;
        option.yAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:0,
                        position:'insideLeft'
                    }
                }
            })
        } else {
            series = [];
        }
        option.series = series;
    } else if(type==='stackBar') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;        
        option.dataZoom = getDataZoom();
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    stack:'total',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:90,
                        position:'insideBottom'
                    }
                }
            })
        } else {
            series = [];
        }
        option.series = series;
    } else if(type==='stackRotateBar') {
        option = {...rotateBarOptions};
        option.xAxis.data = data.yNames;
        option.yAxis.data = data.xNames;
        option.legend.data = data.yNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'bar',
                    stack:'total',
                    barMaxWidth:30,
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,
                        rotate:0,
                        position:'insideLeft'
                    }
                }
            })
        } else {
            series = [];
        }
        option.series = series;
    }  else if(type==='area') {
        option = {...lineOptions};
        option.xAxis.data = data.xNames;
        option.legend.data = data.yNames;
        option.dataZoom = getDataZoom();
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    type:'line',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    data:item,
                    label:{
                        show:flag2,
                        distance: 15,
                        align: 'left',
                        verticalAlign: 'middle',
                        formatter: '{c}',
                        fontSize: 12,  
                        rotate:0,  
                        position:'top'  
                    }
                }
            });
        } else {
            series = [];
        }
        option.series = series;
    } else if(type==='pie') {
        option = {...pieOption};
        option.legend.data = data.xNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas[0].map((item,key)=>{
                return {
                    name:data.xNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].name = data.yNames[0];
        option.series[0].radius = ['0', '70%'];
        option.series[0].data = series;
    } else if(type==='ring') {
        option = {...pieOption};
        option.legend.data = data.xNames;
        let series;
        if(data.datas.length>0) {
            series = data.datas[0].map((item,key)=>{
                return {
                    name:data.xNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].name = data.yNames[0];
        option.series[0].radius = ['40%', '70%'];
        option.series[0].data = series;
    } else if(type==='radar') {
        option = {...radarOption};
        option.radar.indicator = data.xNames.map(item=>{
            return {name:item,max:data.maxVal}
        });
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].areaStyle = null;
        option.series[0].data = series;
    } else if(type==='fillRadar') {
        option = {...radarOption};
        option.radar.indicator = data.xNames.map(item=>{
            return {name:item,max:data.maxVal}
        });
        let series;
        if(data.datas.length>0) {
            series = data.datas.map((item,key)=>{
                return {
                    name:data.yNames[key],
                    value:item
                }
            });
        } else {
            series = [];
        }
        option.series[0].areaStyle = {};
        option.series[0].data = series;
    } else if(type==='scatter') {
        option = {...scatterOption},
        option.series[0].data = data.data;
        option.title.text = data.xName;
        option.yAxis.name = data.yName;
    }
    if(option.legend) {
        option.legend.show = flag;
    } else {
        option.legend = {
            show:flag
        }
    }
    chartHandle.setOption(option);
}
      

export const playBigChart=(chartHandle,flag)=> {
    let option = {...option};
    if(!flag) {
        var i = 10;  
        timer = setInterval(() => {
            i+=10;
            let zoom = getDataZoom(i);
            option.dataZoom = zoom;
            chartHandle.setOption(option);            
            if(i===100) {
                i=10;
            } 
        }, 2000);
        
    } else {
        if(timer) {
            clearInterval(timer);
            let zoom = getDataZoom(100);
            option.dataZoom = zoom;
            chartHandle.setOption(option);
        }
    }  
}

function getDataZoom(value) {
    return [
        {
            type: 'slider',
            filterMode: 'weakFilter',
            showDataShadow: false,
            bottom: 35,
            height: 10,
            start: 0,
            end: value?value:100, 
            borderColor: 'transparent',
            backgroundColor: '#e2e2e2',
            handleIcon: 'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z', // jshint ignore:line
            handleSize: 20,
            handleStyle: {
                shadowBlur: 6,
                shadowOffsetX: 1,
                shadowOffsetY: 2,
                shadowColor: '#aaa'
            },
            labelFormatter: ''
        }, {
            type: 'inside',
            filterMode: 'weakFilter'
        }
    ]
}