<template>
    <a-modal v-model="filterVisible" title="自定义函数" :footer="null" :width="600" :centered="true">
        
        <div class="biaodashi">
            <a-row>
                <a-col :span="10">
                    <div class="label">自定义变量名称：</div>
                    <a-input v-model="dName" />
                </a-col>
                <a-col :span="4">
                    <div class="label"></div>
                    <div class="text">=</div>
                </a-col>
                <a-col :span="10">
                    <div class="label">计算表达式：</div>
                    <input v-model="cobol" type="text" class="ant-input" @click="getCursortPosition" @keyup="getCursortPosition" id="txt1" ref="cobol" />
                </a-col>
            </a-row>
        </div>

        <div class="variables">
            <div class="h2">已有变量变量：<span class="red">*双击变量添加至计算表达式</span></div>
            <div class="list">
                <div class="check-item" v-for="item in list" :key="item.index" @dblclick="insertVars(item.index)">
                    [s{{item.index}}]{{item.key}}
                </div>
            </div>
        </div>

        <div class="ctrl-btns">
            <div class="number-key">
                <span  class="btn" v-for="item in numberBtn" :key="item" @click="insertNum(item)">{{item}}</span>
            </div>
            <div class="func-key">
				<span class="btn" @click="insertFn('RECIPORC()')" title="取倒数">1/x</span>
				<span class="btn" @click="insertStr('^')" title="乘方">^</span>
				<span class="btn" @click="insertStr('π')">π</span>
				<span class="btn" @click="insertFn('ABS()')">abs</span>
				<span class="btn" @click="insertFn('LOG()')">log</span>
				<span class="btn" @click="insertFn('LN()')">ln</span>
				<span class="btn" @click="insertFn('EXP()')">exp</span>
				<span class="btn" @click="insertNum('%')">%</span>
				<span class="btn" @click="insertFn('ROOT()')">√￣</span>
				<span class="btn" @click="delStr()" title="后退">←</span>
				<div class="clear"></div>
			</div>
			<div class="ctrl-key">
				<span class="btn" @click="reset()">c</span>
				<span class="btn" @click="insertStr('e')">e</span>
				<span class="btn" @click="insertStr('*')">*</span>
				<span class="btn" @click="insertStr('(')">(</span>
				<span class="btn" @click="insertStr(')')">)</span>
				<span class="btn" @click="insertNum('.')">.</span>
				<span class="btn" @click="insertStr('/')">/</span>
				<span class="btn" @click="insertStr('+')">+</span>
				<span class="btn" @click="insertStr('-')">-</span>
				<span class="btn" @click="goApply()">=</span>
				<div class="clear"></div>
			</div>
			<div class="clear">
				<span class="red" style="font-weight: normal;color:#f00;"></span>
			</div>
        </div>

        <div class="btns">
            <a-button type="primary" ghost @click="cancle">取消</a-button>
            <a-button type="primary" @click="goApply">应用</a-button>
        </div>
    </a-modal>
</template>

<script>
import { messageTips } from '../until/tools';

export default {
    name:'TableCalculate',
    props:['visible','sheetId'],
    data() {
        return {
            filterVisible:false,
            list:[],
            numberBtn:[
                1,2,3,4,5,6,7,8,9,0
            ],
            cobol:'',
            dName:'',
            cursurPosition:0
        }
    },
    watch:{
        visible(value) {
            if(value) {
                this.filterVisible = true;
                this.cobol = '';
                this.dName = '';
                this.cursurPosition = 0;
                this.$http.getVars({sheetId:this.sheetId}).then(res=>{
                    if(res) {
                        this.list = res;
                    }
                });
            } else {
                this.filterVisible = false;
            }
        }
    },
    methods:{
        cancle() {
            this.filterVisible = false;
        },

        insertNum(e) {
            this.cobol = this.handleStr(this.cursurPosition,this.cobol,e);
            this.cursurPosition+=e.toString.length;
        },
        insertFn(e) {
            this.cobol = this.handleStr(this.cursurPosition,this.cobol,e);
            this.cursurPosition+=(e.length-1);
        },
        insertStr(e) {
            this.cobol = this.handleStr(this.cursurPosition,this.cobol,e);
            this.cursurPosition+=e.length;
        },
        insertVars(e) {
            if(e===0) {
				return false;
			}
			let dept_value = '[s'+e+']';
			this.cobol = this.handleStr(this.cursurPosition,this.cobol,dept_value);
			this.cursurPosition = this.cursurPosition+dept_value.length;
        },
        reset() {
            this.cobol = '';
        },
        delStr() {
            var ystr = this.cobol; 
			var num = this.cursurPosition;
			var ylength = ystr.length;
			var str1 = ystr.substr(0,num-1);
			var str2 = ystr.substr(num,ylength);
			var lastStr = str1+str2;
			if(this.cursurPosition>0) {
				this.cursurPosition = this.cursurPosition-1;
			}
			this.cobol = lastStr;
        },
        goApply() {
            if(this.dName==='') {
				messageTips('请输入自定义变量名称',2);
				return false;
			}
			
			if(this.cobol ==='') {
                messageTips('请输入计算表达式',2);
				return false;
			} else {
				var pattern = /\[s\d{1,}\]/;
				var str = this.cobol;			
				var pattern2 = /[\u4E00-\u9FA5]/g;
				if(pattern.test(str)===false) {
                    messageTips('计算表达式中没有变量',2);
					return false;
				}
				if(pattern2.test(str)!==false) {
                    messageTips('计算表达式中含有非法字符',2);
					return false;
				}
            }
            
            let vars='';
			this.list.forEach((v,k)=>{
                if(k==(this.list.length-1)) {
					vars += '[s'+v.index+']';
				} else {
					vars += '[s'+v.index+'],';
				}
            });
			var params = {
                formula:this.cobol,
                vars:vars,
                outName:this.dName,
                sheetId:this.sheetId
            }
            this.$emit('userFunction',{name:'userFunction',data:params});
            this.filterVisible = false;
        },
        
        getCursortPosition() {
            var oTxt1 = this.$refs.cobol;
            var cursurPosition=-1;
            if(oTxt1.selectionStart){//非IE浏览器
                cursurPosition= oTxt1.selectionStart;
            }else{//IE
                if(document.selection) {
                    var range = document.selection.createRange();
                    range.moveStart("character",-oTxt1.value.length);
                    cursurPosition=range.text.length;
                }                
            }
            this.cursurPosition = cursurPosition;
        },
        handleStr(num,ystr,nstr) {
			var ylength = ystr.length;
			var str1 = ystr.substr(0,num);
			var str2 = ystr.substr(num,ylength);
			var lastStr = str1+nstr+str2;
			return lastStr;
		}
    }
}
</script>

<style lang="scss" scoped>
.h2 {
    font-weight: bold;
    padding-bottom: 10px;
}
.biaodashi {
    .label {
        height:22px;
        line-height: 22px;
        font-weight: bold;
    }
    .text {
        line-height: 32px;
        text-align: center;
    }
}

.variables {
    padding-top: 20px;
    .h2 {
        .red {
            color:#f00;
            font-weight: normal;
        }
    }
    .list {
        height:150px;
        border:1px solid #ddd;
        padding:5px 10px;
        overflow-y: auto;
        .check-item {
            padding:3px 0;
            cursor: pointer;
        }
    }
}

.ctrl-btns {
    padding-top: 20px;
    .func-key {
        color:#114f78;
    }
    .number-key {
        color:#0d8dce;
    }
    .ctrl-ey {
        color:#124f7b;
    }
    .btn {
        float:left;
        width:42px;
        border:1px solid #e8e8e8;
        margin:5px 6px 5px 6px;
        text-align:center;
        height:29px;
        line-height:29px;
        font-weight:bold;
        border-radius:3px;
        box-shadow:0 1px 2px 0 #ddd;
        background: linear-gradient(0deg, #f6f6f6 50%, #fefefe 100%);
        cursor: pointer;
    }
    .btn:hover {
        background:#2f9cd4;
        color:#fff;
    }
}

</style>